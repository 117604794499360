// @ts-ignore
import Swiper, { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';

export default () => {
    document.querySelectorAll('.header-slider__swiper').forEach((el) => {
        if (el.classList.contains('swiper-initialized')) return;

        new Swiper(el as HTMLElement, {
            modules: [Navigation, Pagination, Autoplay, EffectFade],
            // Optional parameters
            direction: 'horizontal',
            loop: true,
            speed: 1400,
            effect: 'fade',
            autoplay: {
                delay: 7000
            },
            grabCursor: true,
            pagination: {
                el: el.querySelector('.swiper-pagination') as HTMLElement,
                clickable: true
            }
        });
    });

    document.querySelectorAll('.swiper-container').forEach((el) => {
        if (el.classList.contains('swiper-initialized')) return;

        new Swiper(el as HTMLElement, {
            modules: [Navigation, Pagination, Autoplay],
            // Optional parameters
            direction: 'horizontal',
            loop: true,
            grabCursor: true,
            navigation: {
                nextEl: el.parentElement.querySelector('.swiper-button-next') as HTMLElement | undefined,
                prevEl: el.parentElement.querySelector('.swiper-button-prev') as HTMLElement | undefined,
            },
            pagination: {
                el: el.querySelector('.swiper-pagination') as HTMLElement,
                clickable: true
            },
            ...(el.classList.contains('swiper-container-logos') ? {
                speed: 1000,
                autoplay: {
                    delay: 2000
                },
                slidesPerView: 3,
            } : {})
        });
    });
};
